@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'IntelOne Display';
    src: url('./fonts/intelone-display-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntelOne Display';
    src: url('./fonts/intelone-display-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntelOne Display';
    src: url('./fonts/intelone-display-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntelOne Display';
    src: url('./fonts/intelone-display-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-lightitalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-mediumitalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'IntelOne Text';
    src: url('./fonts/intelone-text-bolditalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
